<template>
    <div class="con-wrap">
        <CarrotTitle title="직원 등록">
            <p class="color-1">* ID(Email), 비밀번호는 당근농장 로그인 시 사용되는 계정으로 등록 이후 수정이 불가합니다.</p>
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div class="mb-20">
                        <span class="block-title float-left">인적사항</span>
                        <span class="float-right color-1">* 전체 항목 필수 입력입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>이름</th>
                                <td>
                                    <input type="text" class="w-200px" v-model.trim="add.kname" maxlength="50">
                                </td>
                                <th>영문이름</th>
                                <td>
                                    <input type="text" class="w-200px" v-model.trim="add.ename" maxlength="50">
                                </td>
                            </tr>
                            <tr>
                                <th>주민등록번호</th>
                                <td>
                                    <div>
                                        <label><input type="radio" id="number_ra1" name="number_ra" value="N" v-model="add.is_foreigner"><span class="ml-5 mr-20"> 내국인</span></label> 
                                        <input type="text" class="w-150px" v-if="add.is_foreigner=='N'" v-model="add.ssn1" maxlength="6">
                                        <span v-if="add.is_foreigner=='N'"> - </span>
                                        <input type="text" class="w-150px" v-if="add.is_foreigner=='N'" v-model.number="add.ssn2" maxlength="7">
                                    </div>
                                    <div class="mt-5">
                                        <label><input type="radio" id="number_ra2" name="number_ra" value="Y" v-model="add.is_foreigner"><span class="ml-5 mr-20"> 외국인</span></label> 
                                        <input type="text" class="w-150px" v-if="add.is_foreigner=='Y'" v-model.trim="add.ssn1" placeholder="외국인등록번호" maxlength="50">
                                    </div>
                                </td>
                                <th>성별</th>
                                <td>
                                    <label><input type="radio" id="MY_ra1" name="MY_ra" value="M" v-model="add.gender"><span class="ml-5 mr-20"> 남</span></label>
                                    <label><input type="radio" id="MY_ra2" name="MY_ra" value="F" v-model="add.gender"><span class="ml-5 mr-20"> 여</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>생년월일</th>
                                <td>
                                    <label><input type="radio" id="Bir_ra1" name="Bir_ra" value="N" v-model="add.is_lunar"><span class="ml-5 mr-20"> 양력</span></label>
                                    <label><input type="radio" id="Bir_ra2" name="Bir_ra" value="Y" v-model="add.is_lunar"><span class="ml-5 mr-20"> 음력</span></label>
                                    <div class="mt-5">
                                        <CarrotDatePicker v-model="add.birth" class="w-110px float-left"></CarrotDatePicker>
                                    </div>
                                </td>
                                <th>결혼기념일</th>
                                <td>
                                    <div>
                                        <label><input type="radio" id="Mar_ra1" name="Mar_ra" value="N" v-model="add.is_married"><span class="ml-5 mr-20"> 미혼</span></label>
                                    </div>
                                    <div class="mt-5">
                                        <label class="float-left"><input type="radio" id="Mar_ra2" name="Mar_ra" value="Y" v-model="add.is_married"><span class="ml-5 mr-20"> 기혼</span></label>
                                        <CarrotDatePicker v-if="add.is_married=='Y'" v-model="add.married_date" class="w-110px float-left"></CarrotDatePicker>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>연락처</th>
                                <td>
                                    <input type="text" class="w-200px" maxlength="20" v-model.trim="add.phone">
                                </td>
                                <th>비상연락망</th>
                                <td>
                                    <input type="text" class="w-200px" maxlength="20" v-model.trim="add.emergency_tel">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div class="mt-50 mb-20">
                        <span class="block-title float-left">인사정보</span>
                        <span class="float-right color-1">* 항목 필수 입력입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>입사일자</th>
                                <td colspan="3">
                                    <CarrotDatePicker v-model="add.joindate" class="w-110px float-left" readonly="Y"></CarrotDatePicker>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> ID(Email)</th>
                                <td>
                                   <input type="text" class="w-190px mb-5" maxlength="20" v-model.trim="add.uid" @input="add.uidChange()">
                                   <select class="w-160px mb-5" v-model="add.uidType" @change="add.uidChange()">
                                        <option value="@carrotglobal.com">@carrotglobal.com</option>
                                        <option value="@bhappy.co.kr">@bhappy.co.kr</option>
                                    </select>
                                    <button @click="add.dupChkUid()" class="btn-default h-30px">중복체크</button>
                                </td>
                                <th><span class="txt-red">*</span> 비밀번호</th>
                                <td>
                                    <input type="text" class="w-250px" maxlength="20" v-model="add.upw">
                                </td>
                            </tr>
                            <tr>
                                <th>본부/팀</th>
                                <td colspan="3">
                                    <CarrotSector v-model="add.idx_sector" class="w-200px"></CarrotSector>
                                    <CarrotDept v-model:idx_sector.sync="add.idx_sector"  v-model="add.idx_office" ></CarrotDept>
                                    <CarrotTeam :idx_sector="add.idx_sector" :idx_office="add.idx_office" v-model="add.idx_team"></CarrotTeam>
                                </td>
                            </tr>
                            <tr>
                                <th>직급</th>
                                <td>
                                    <CarrotPosition type="A" v-model="add.idx_position"></CarrotPosition>
<br>
                                  <label v-if="add.idx_position==6150"><input  type="checkbox" value="Y" v-model="add.auth_ctm"><span class="ml-5 mr-20"> CTM</span></label>
                                  <label v-if="add.idx_position==6150"><input type="checkbox" value="Y" v-model="add.auth_itm"><span class="ml-5 mr-20"> ITM</span></label>
                                  <label v-if="add.idx_position==6150"><input type="checkbox" value="Y" v-model="add.auth_mango"><span class="ml-5 mr-20"> MangoTalk</span></label>
                                  <span v-if="add.idx_position==6150"><br>※ ITM/CTM 계정 동기화 주기는 매 시 정각 입니다.</span>
                                </td>
                                <th>법인</th>
                                <td>
                                    <CarrotCorporation v-model="add.corporation"></CarrotCorporation>
                                    <!-- <select class="w-200px" v-model="add.corporation">
                                        <option value="">선택</option>
                                        <option value="CK">캐럿코리아</option>
                                    </select> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="add.goList">목록</button>
                    <button class="btn-default float-right mt-30" @click="add.doSubmit">등록</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import CarrotCorporation from '@/components/common/CarrotCorporation.vue'
import { useStore } from 'vuex';
import CarrotSector from "@/components/common/CarrotSector";



export default {
    layout:"myPIMS",
    components: {
        CarrotDatePicker,
        CarrotDept,
        CarrotTeam,
        CarrotCorporation,
        CarrotSector
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const store  = useStore();

        const pop = reactive({
            // Popup 노출 여부
            isShow: false
        });

        const add = reactive({
            // 기본입력사항
            kname : "", ename : "",
            ssn1  : "", ssn2  : "", is_foreigner : "N",
            gender : "M",
            birth  : "", is_lunar : "N",
            is_married : "N", married_date : "",
            phone : "", emergency_tel : "",

            // 추가입력사항
            joindate : "",
            uid : "", upw : "carrot", // 필수
            uidType: "@carrotglobal.com",
            idx_office : 0,   idx_team : 0,
            idx_position : "0", corporation : "",
            idx_sector : 0,
            auth_ctm:"N",
            auth_itm:"N",
            auth_mango:"N",
            is_dupUid_chk:false, //아이디 중복여부
            doSubmit : () => {
              if(add.auth_ctm==true)
                add.auth_ctm='Y'
              if(add.auth_itm==true)
                add.auth_itm='Y'
              if(add.auth_mango==true)
                add.auth_mango='Y'

                if(isNaN(add.ssn1)){
                  alert("주민번호는 숫자만 입력해주세요");
                  return;
                }

                let params = {
                    // 기본입력사항
                    kname : add.kname, 
                    ename : add.ename, 
                    ssn1  : add.ssn1.toString(),  
                    ssn2  : add.ssn2.toString(),  
                    is_foreigner  : add.is_foreigner,
                    gender        : add.gender, 
                    birth         : add.birth,  
                    is_lunar      : add.is_lunar, 
                    is_married    : add.is_married,  
                    married_date  : add.married_date, 
                    phone         : add.phone,  
                    emergency_tel : add.emergency_tel, 

                    uid : add.uid+add.uidType,  // 필수
                    upw : add.upw,  // 필수

                    // 추가입력사항
                    joindate     : add.joindate,
                    idx_sector   : add.idx_sector,
                    idx_office   : add.idx_office,
                    idx_team     : add.idx_team,
                    idx_position : add.idx_position, 
                    corporation  : add.corporation,
                    auth_itm : add.auth_itm,
                    auth_ctm : add.auth_ctm,
                    auth_mango  : add.auth_mango,
                };

                if( params.kname == "" ){
                    alert("이름을 입력하세요.");
                    return ;
                }

                if( params.ename == "" || /^[A-Za-z ]/.test(params.ename) == false ){
                    alert("영문이름을 입력하세요.");
                    return ;
                }

                if( params.is_foreigner == "N" && ( params.ssn1 == "" || params.ssn1.length != 6 || /^[0-9]/.test(params.ssn1) == false || params.ssn2 == "" || params.ssn2.length != 7 || /^[0-9]/.test(params.ssn2) == false ) ){
                    alert("주민등록번호를 입력하세요.");
                    return ;
                }

                if( params.is_foreigner == "Y" && params.ssn1 == "" ){
                    alert("외국인등록번호를 입력하세요.");
                    return ;
                }

                if( params.gender == "" ){
                    alert("성별을 선택하세요.");
                    return ;
                }

                if( params.birth == "" && /^[0-9-]/.test(params.birth) == false && params.birth.length != 10 ){
                    alert("생년월일을 입력하세요.");
                    return ;
                }
                if( params.is_lunar == "" ){
                    alert("양력/음력을 선택하세요.");
                    return ;
                }

                if( params.is_married == "" ){
                    alert("결혼 유무를 선택하세요.");
                    return ;
                }
                if( params.is_married == "Y" && (params.married_date == "" || /^[0-9-]/.test(params.married_date) == false || params.married_date.length != 10 ) ){
                    alert("결혼기념일을 입력하세요.");
                    return ;
                }

                if( params.phone == "" ){
                    alert("연락처를 입력하세요.");
                    return ;
                }

                if( params.emergency_tel == "" ){
                    alert("비상연락망을 입력하세요.");
                    return ;
                }

                if( params.uid == "" ){
                    alert("ID를 입력하세요.");
                    return ;
                }

                if( add.is_dupUid_chk !== true) {
                    alert("아이디 중복체크를 해주세요.");
                    return ;
                }

                if( params.upw == "" ){
                    alert("비밀번호를 입력하세요.");
                    return ;
                }

                if( confirm("등록하시겠습니까?") == true ){
                    axios.post("/rest/mypims/addNewHQ", params).then((res) => {
                        if( res.data.err == 0 ){
                            alert("등록하였습니다.");
                            router.push({
                                name : "myPIMS-IncumbentManagementList"
                            });
                        } else {
                            alert(res.data.err_msg);
                        }
                    });
                }
            },

            goList : () => {
                if( confirm("입력하신 내용이 사라집니다. 목록으로 이동하시겠습니까?") == true ){
                    router.push({
                        name : "myPIMS-IncumbentManagementList",
                        params : route.params
                    });
                }
            },
            //이메일[아이디] 변경 감지
            uidChange : () => {
                add.is_dupUid_chk = false;
            },
            //이메일[아이디] 중복체크
            dupChkUid : () => {
                
                if(add.uid == "") {
                    return alert("ID를 입력해주세요");
                }
                let params = { email : add.uid+add.uidType };
                axios.post("/rest/mypims/dupChkUidNewHq", params).then((res) => {
                    if(res.data.err === 0) {
                        alert("사용가능한 아이디입니다");
                        add.is_dupUid_chk = true;
                    } else {
                        alert(res.data.err_msg);
                        add.is_dupUid_chk = false;
                    }
                });
            },
        });
        
        onMounted(() => {
            if( store.state.pd_auth == false ) {
                router.go(-1);
                Swal.fire({
                    title : '재직자 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

        });

        onUnmounted(() => {

        });

        return {pop, add};
    }
}
</script>

<style lang="scss" scoped>
</style>